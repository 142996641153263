/* eslint-disable react/no-multi-comp */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Typography } from '@material-ui/core';

export const FormatMoney = props => {
  const { inputRef, onChange, onBlur, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
      suffix=" VND"
      thousandSeparator
    />
  );
};

FormatMoney.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const formatRowTableText = ({ value, money, bold, suffix = ' VND' }) => {
  let text = value;
  if (money) {
    text = formatMoneyText(text, suffix);
  }
  if (bold) {
    text = (
      <Typography component="h1" variant="h3">
        {text}
      </Typography>
    );
  }
  return text;
};

export const formatMoneyText = (value, suffix = ' VND') => {
  return (
    <NumberFormat
      displayType={'text'}
      suffix={suffix}
      thousandSeparator
      value={value}
    />
  );
};

const convertFormat = length => {
  if (length < 11) return '(###) ###-#####';
  else if (length === 11) return '(####) # ###-###';
  else return undefined;
};

export const FormatPhone = props => {
  const { inputRef, onChange, onBlur, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      format={convertFormat(other.value.length)}
      getInputRef={inputRef}
      mask=" "
      onValueChange={values => {
        onChange({
          target: {
            name: name,
            value: values.value
          }
        });
        onBlur({
          target: {
            name: name,
            value: values.value
          }
        });
      }}
    />
  );
};

export const FormatPhoneNumber = props => {
  const { value } = props;

  return (
    <NumberFormat
      displayType={'text'}
      format={convertFormat(value.length)}
      value={value}
    />
  );
};

FormatPhone.propTypes = {
  inputRef: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func
};

export const roundTimeQuarterHour = () => {
  var timeToReturn = new Date();

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return timeToReturn;
};

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function roundTimeQuarterHourWithDate(
  date,
  format = 'YYYY-MM-DD HH:mm:ss'
) {
  var timeToReturn = new Date(date);

  timeToReturn.setHours(0);
  timeToReturn.setSeconds(0);
  timeToReturn.setMinutes(0);
  return moment(timeToReturn).format(format);
}

export const removeAccents = str =>
  str
    ?.normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D');
